import Notifications from "views/pages/components/Notifications";

import api, { apiDelete, apiGet, apiPost, apiPut } from "./Api";


export const getAllDeliveryTeam = async () => {
    try {
        let response = await apiGet({
            url: `deliveryTeam/getAllDeliveryTeam`,
            responseType: "",
        });
        return response.status == 200
            ? response.data.data.rows
            : response.status;
    } catch (e) {
        return [];
    }
};

export const createDeliveryTeam = async (deliveryTeamName, driverID, carID, truckWorkerList) => {
    // truckWorkerList format
    // truckWorkerList = [{truckWorkerID: 'dfds'}, {truckWorkerID: '2313'}, ....]
    if (!truckWorkerList || !Array.isArray(truckWorkerList) || truckWorkerList.length < 0) {
        return null;
    }
    const dataToSubmit = {
        deliveryTeamName: deliveryTeamName,
        driverID: driverID,
        carID: carID,
        truckWorkerIDs: truckWorkerList
    }

    let response = await apiPost({
        url: "deliveryTeam/createDeliveryTeam",
        data: dataToSubmit,
        responseType: "",
    });
    return response.data.data.deliveryTeamID;
};


export const deleteDeliveryTeam = async (deliveryTeamID) => {
    let response = await apiDelete({
        url: "deliveryTeam/deleteDeliveryTeam/" + deliveryTeamID,
        responseType: "",
    });
    return response.status == 200
        ? response.data
        : response.status;
};


export const updateDeliveryTeam = async (deliveryTeamID, deliveryTeamName, driverID, carID, truckWorkerList) => {
    // truckWorkerList format
    // truckWorkerList = [{truckWorkerID: 'dfds'}, {truckWorkerID: '2313'}, ....]
    if (!truckWorkerList || !Array.isArray(truckWorkerList) || truckWorkerList.length < 0) {
        return null;
    }
    const dataToSubmit = {
        deliveryTeamID: deliveryTeamID,
        deliveryTeamName: deliveryTeamName,
        driverID: driverID,
        carID: carID,
        truckWorkerIDs: truckWorkerList
    }
    let response = await apiPut({
        url: "deliveryTeam/updateDeliveryTeam",
        data: dataToSubmit,
        responseType: "",
    });
    
    return response.status == 200
    ? response.data
    : response.status;
};

















export const addDeliveryAddress = async (data) => {
    let response = await apiPost({
        url: "deliveryAddress/addAddress",
        data: data,
        responseType: "",
    });
    return response.status == 200
        ? response.data.data
        : response.status;
};

