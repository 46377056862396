import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { paginationTable } from "functions/PaginationTable";
import { isAccessible } from "functions/SystemUserAccess.js";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Label,
  Badge,
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {
  deleteUserByID,
  getUserList,
  getUserListQuitted,
  isLoggedIn,
} from "../../../functions/Auth";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Notifications from "../components/Notifications";
import UserModalCreate from "./UserModalCreate";
import UserModalEdit from "./UserModalEdit";
import UserModalEditPassword from "./UserModalEditPassword";
import { joinArrayToString, addPhoneLink } from "functions/Utility.js";

import SpinnerOverlay from "../components/SpinnerOverlay";
import ScreenPullToRefresh from "../components/ScreenPullToRefresh";
import { lockScrolling } from "functions/Utility";
import AddressModalCreate from "../address/AddressModalCreate";

import CustomerModalPhoneCreate from "../customer/CustomerModalPhoneCreate";
import { getAccessibleTagForSafeMode } from "functions/SystemUserAccess";


function UserScreen(props) {
  const { t } = useTranslation();
  const pagination = paginationTable();


  const { SearchBar } = Search;

  const [isLoading, setIsLoading] = React.useState(false);
  const [refreshTable, setRefreshTable] = React.useState(0);

  const [userData, setUserData] = React.useState([]);
  const [selectedUserData, setSelectedUserData] = React.useState([]);

  // GUI
  const [pullToRefreshDisable, setPullToRefreshDisable] = React.useState(false);
  const [expandChoice, setExpandChoice] = React.useState(false);
  const [quittedUserMode, setQuittedUserMode] = React.useState(false);


  // Modal
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = React.useState(false);
  const [modalEditPasswordIsOpen, setModalEditPasswordIsOpen] = React.useState(false);


  const [modalCreateDeliveryAddressIsOpen, setModalCreateDeliveryAddressIsOpen] = React.useState(false);


  React.useEffect(() => {
    // Pull to refresh must be disabled if opened some medal
    // Otherwise will lock the screen of the modal
    setPullToRefreshDisable(modalIsOpen || modalEditIsOpen);
    lockScrolling(modalIsOpen || modalEditIsOpen);
  }, [modalIsOpen, modalEditIsOpen]);



  React.useEffect(() => {
    setPullToRefreshDisable(modalCreateDeliveryAddressIsOpen);
    lockScrolling(modalCreateDeliveryAddressIsOpen);
  }, [modalCreateDeliveryAddressIsOpen]);

  const refreshTableData = () => {
    setRefreshTable(refreshTable + 1);
  };

  const getUserTable = async () => {
    setIsLoading(true);


    let userTable = [];
    if (quittedUserMode) {
      userTable = await getUserListQuitted();
    } else {
      userTable = await getUserList();
    }

    setUserData(userTable);
    setIsLoading(false);
  };

  // Call API User
  React.useEffect(() => {
    getUserTable();
  }, [refreshTable]);

  // Delete Confirmation
  async function deleteConfirmation(data) {
    let alert = {
      type: "deleteConfirmation",
      title: "Are you Sure?",
      message: "Data Will Be Deleted",
      elementID: data,
      callback: deleteUser,
    };
    Notifications(alert);
  }

  // Delete API Call
  async function deleteUser(data) {

    let deleteResponse = await deleteUserByID(data.userID);
    deleteResponse.code == 200 ? refreshTableData() : true;
  }

  return (isLoggedIn() && isAccessible(props.accessID)) ? (
    <>
      {isLoading ? <SpinnerOverlay /> : ""}

      <ScreenPullToRefresh
        onRefresh={async () => {
          await getUserTable();
        }}
        disabled={pullToRefreshDisable}>
        <SimpleHeader parentName={t("User")} icon="fas fa-home" />
      </ScreenPullToRefresh>
      <Container style={{ marginTop: "-4rem" }} fluid>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <ToolkitProvider
                  data={userData}
                  keyField="userID"
                  columns={
                    [
                      // hidden columns are for search
                      { dataField: "userID", text: "", hidden: true },
                      { dataField: "nickName", text: "", hidden: true },
                      { dataField: "name", text: "", hidden: true },
                      { dataField: "chineseName", text: "", hidden: true },
                      { dataField: "phoneNumber", text: "", hidden: true },
                      { dataField: "SystemUserJobMatchesJoin", text: "", hidden: true, formatter: (cell, row, rowIndex, extraData) => { return `${joinArrayToString(row.SystemUserJobMatches.map((item) => { return item.job + t(item.job) }), "")}` } },
                      {
                        dataField: "",
                        text: (
                          <span className="tableTitle">{t("Name")}</span>
                        ),
                        formatter: (cell, row, rowIndex, extraData) => {
                          return (
                            <div
                              key={rowIndex}
                              onClick={() => {
                                setSelectedUserData(row);
                                setModalEditIsOpen(true);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <div >
                                <Badge className="statusBadge" color={row.status === 'normal' ? 'success' : 'danger'}>
                                  <Trans>{row.status}</Trans>
                                </Badge>
                              </div>
                              <div className="tableRowTitle">
                                {row.name} ({row.nickName})
                              </div>
                              <div className="tableRowSubTitle">
                                {row.userID} {row.chineseName} {addPhoneLink(row.phoneNumber)}
                              </div>
                              <div>
                                {row?.SystemUserJobMatches?.map((item) => {
                                  return <Badge className="statusBadge" color="primary">
                                    {t(item.job)}
                                  </Badge>
                                })}
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        isDummyField: true,
                        dataField: "Action",
                        text: "",
                        formatter: (cellContent, row) => {
                          return (
                            <>
                              {/* <a
                                    className="table-action"
                                    id="tooltip209424781"
                                    onClick={() => {
                                      setSelectedUserData(row);
                                      setModalEditIsOpen(true);
                                    }}
                                  >
                                    <i className="fas fa-edit fa-lg" />
                                  </a>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip209424781"
                                  >
                                    {t("Edit")}
                                  </UncontrolledTooltip> */}

                              {isAccessible('userManageEditChangeOtherUserPassword') &&
                                <>
                                  <a
                                    className="table-action"
                                    id="tooltip209424777"
                                    onClick={() => {
                                      setSelectedUserData(row);
                                      setModalEditPasswordIsOpen(true);
                                    }}
                                  >
                                    <i className="fas fa-key fa-lg" />
                                    <span>{getAccessibleTagForSafeMode('userManageEditChangeOtherUserPassword')}</span>

                                  </a>
                                  {/* <UncontrolledTooltip
                                      delay={0}
                                      target="tooltip209424777"
                                    >
                                      {t("password")}
                                    </UncontrolledTooltip> */}
                                </>
                              }


                              <a
                                className="table-action table-action-delete"
                                id="tooltip12475020"
                                onClick={() => deleteConfirmation(row)}
                              >
                                <i
                                  className="fas fa-trash fa-lg"
                                  data-id={cellContent}
                                />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip12475020"
                              >
                                {t("Delete")}
                              </UncontrolledTooltip>
                            </>
                          );
                        },
                      },
                    ]
                  }
                  search={{
                    searchFormatted: true
                  }}
                >
                  {(props) => (
                    <>
                      <Row className="pb-2">
                        <Col className="col-6">
                          <SearchBar
                            className=""
                            placeholder={t("Search")}
                            {...props.searchProps}
                          />
                        </Col>
                        <Col className="col-6 text-right">
                          <Button
                            className="mr-1"
                            color="primary"
                            size="md"
                            onClick={() => {
                              setExpandChoice(!expandChoice);
                            }}
                          >
                            <span className="btn-inner--icon">
                              <i className={expandChoice ? "fa fa-angle-up" : "fa fa-angle-down"} />
                            </span>
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => setModalIsOpen(!modalIsOpen)}
                            size="md"
                          >
                            <span className="btn-inner--icon">
                              <i className="fas fa-plus" />
                            </span>
                          </Button>
                        </Col>
                      </Row>

                      <Row className="pb-2">
                        <Col>
                          {expandChoice &&
                            <Button
                              color={quittedUserMode ? 'success' : 'danger'}
                              id="tooltip443412080"
                              onClick={() => {
                                setQuittedUserMode(!quittedUserMode);
                                refreshTableData();
                              }}
                              size="md"
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className={quittedUserMode ? "fas fa-check" : "fas fa-times"} />
                              </span>
                              <span className="btn-inner--icon">
                                <Trans>Quitted users</Trans>
                              </span>
                            </Button>
                          }
                        </Col>
                      </Row>


                      <div className="py-2 table-responsive table-sm">
                        <BootstrapTable
                          headerClasses="tableHiddenHeader"
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                        />
                      </div>
                    </>
                  )}
                </ToolkitProvider>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>

      </Container>
      <UserModalCreate
        show={modalIsOpen}
        onHide={(e) => {
          setModalIsOpen(false);
        }}
        title={t("User Information")}
        button={t("Save")}
        refreshTable={refreshTableData}
      />
      <UserModalEdit
        show={modalEditIsOpen}
        onHide={() => setModalEditIsOpen(false)}
        title={t("User Information")}
        button={t("Update")}
        userData={selectedUserData}
        refreshTable={refreshTableData}
      />
      <UserModalEditPassword
        show={modalEditPasswordIsOpen}
        onHide={() => setModalEditPasswordIsOpen(false)}
        title={t("Change Password")}
        button={t("Update")}
        userData={selectedUserData}
        refreshTable={refreshTableData}
      />

      <AddressModalCreate
        show={modalCreateDeliveryAddressIsOpen}
        onHide={(e) => {
          setModalCreateDeliveryAddressIsOpen(false);
        }}
        title={t("Create new address")}
        button={t("Add")}
        refreshTable={refreshTableData}
        deliveryAddressToClone={{}}
        getNewAddressData={(newAddressData) => {
          // do nothing
        }}
      />

    </>
  ) : (
    <Redirect to="/work/signIn" />
  );
}

export default UserScreen;
